import { combineReducers } from "redux";
import auth from "../../auth/store/reducers";
import order from "./order.reducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    order,
    ...asyncReducers,
  });

export default createReducer;
