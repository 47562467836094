import React from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import Chip from "@mui/material/Chip";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import CircularProgress from "components/ErrorHandlers/CircularProgressComponent";
import EditSvg from "assets/edit.svg";
import EditDisabledSvg from "assets/editDisabled.svg";

function ProductsDesktop(props) {
  let items = props.items;
  let itemStatus = { 0: "Pending", 1: "Complete", 2: "Processing" };
  const stringTruncate = (str, length) => {
    const dots = str.length > length ? "..." : "";
    return `${str.substring(0, length)}${dots}`;
  };

  const getProjectListDesktop = props.loading ? (
    <tr>
      <td colspan={8} className="text-center fw-bold m-auto">
        <CircularProgress />
      </td>
    </tr>
  ) : items && items.length > 0 ? (
    items.map((item) => {
      return (
        <tr>
          <td>
            <div className="ml-4 d-flex align-items-center">
              <p className="mb-0 mr-2 prominent">{item.id}</p>
            </div>
          </td>
          <td>{moment(item.createdAt).format("h:mm a, DD-MM-YYYY")}</td>
          <td>{stringTruncate(item.material.name, 45)}</td>
          <td>{stringTruncate(item.customer.name, 45)}</td>

          <td>{item.quantity} KG</td>
          <td>RM {item.sellingPrice} </td>
          <td>{item.deliveryLocation}</td>

          <td>{moment(item.requestDate).format("DD-MM-YYYY")}</td>
          <td>
            <Chip
              className={`${itemStatus[item.status]} fw-bold`}
              label={itemStatus[item.status]}
            />
          </td>
          <td>
            <div className="" style={{ cursor: "pointer" }}>
              <Link
                to={item.status == 0 ? `/editOrder/${item.id}` : "#"}
                style={{ color: "1d1d1e", textDecoration: "none" }}
              >
                <span>
                  {item.status == 0 ? (
                    <img alt="Edit Order" src={EditSvg}></img>
                  ) : (
                    <img alt="Edit Order Disbaled " src={EditDisabledSvg}></img>
                  )}
                </span>
              </Link>
            </div>
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colspan={8} className="text-center m-auto">
        <h4 className="fw-bold">No Orders</h4>
      </td>
    </tr>
  );
  return (
    <div>
      <div className="py-4" style={{ position: "relative" }}>
        <Table responsive className="main-table">
          <thead className="main-table-header">
            <tr>
              <th>
                <span className="ml-4 mb-0 d-flex">{"Order Id"}</span>
              </th>
              <th>{"Order Time"}</th>
              <th>{"Material Name"}</th>
              <th>{"Customer Name"}</th>

              <th>{"Quantity"}</th>
              <th>{"Selling Price"}</th>
              <th>{"Delivery Location"}</th>

              <th>{"Request ETA"}</th>
              <th>{"Status"}</th>
              <th>{"Actions"}</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="main-table-body">{getProjectListDesktop}</tbody>
        </Table>
        <div className="mt-4  d-flex justify-content-end">
          <Pagination
            count={Math.ceil(props.totalCount / 10)}
            page={props.variables.page}
            onChange={(e, page) => {
              props.handlePageChange(page);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}

export default ProductsDesktop;
