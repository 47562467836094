import React from "react";
import Chip from "@mui/material/Chip";
import Pagination from "@material-ui/lab/Pagination";
import CircularProgress from "components/ErrorHandlers/CircularProgressComponent";
import Menu from "@mui/material/Menu";
import EditSvg from "assets/edit.svg";
import EditDisabledSvg from "assets/editDisabled.svg";
import moment from "moment";

import { Link } from "react-router-dom";

function ProductsMobile(props) {
  let itemStatus = { 0: "Pending", 1: "Complete", 2: "Processing" };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDetailClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDetailClose = () => {
    setAnchorEl(null);
  };
  console.log("Props --->", props);
  let renderNotifications = () => {
    return props.loading === true ? (
      <div style={{ display: "grid", placeItems: "center" }}>
        <div
          style={{ minHeight: "40vh" }}
          className="flex flex-col justify-end"
        >
          <CircularProgress />
        </div>
      </div>
    ) : props.loading === false && props.items.length === 0 ? (
      <div
        style={{ minHeight: "40vh" }}
        className="flex flex-col justify-end text-center mt-6"
      >
        <h4 className="fw-bold">No Orders</h4>
      </div>
    ) : (
      props.items.map((item) => {
        return (
          <div class="card text-start mb-4 item_card">
            <div
              class="card-body"
              onClick={() => {
                console.log("item -->", item);
              }}
            >
              <div className="d-flex justify-content-between mb-2">
                <div className="d-flex">
                  <p class="card-text me-4">
                    <small class="text-muted">Order Id {item.id}</small>
                  </p>
                  <Chip
                    size="small"
                    className={`${itemStatus[item.status]} fw-bold`}
                    label={itemStatus[item.status]}
                  />
                </div>
                <Link
                  to={item.status == 0 ? `/editOrder/${item.id}` : "#"}
                  style={{ color: "1d1d1e", textDecoration: "none" }}
                >
                  <span>
                    {item.status == 0 ? (
                      <img alt="Edit Order" src={EditSvg}></img>
                    ) : (
                      <img
                        alt="Edit Order Disbaled "
                        src={EditDisabledSvg}
                      ></img>
                    )}
                  </span>
                </Link>
              </div>
              <h5 className="card-title fw-bold mb-2">{item.customer.name}</h5>
              <div className="mb-2 ">
                <small class="text-muted">{item.material.name}</small>
              </div>
              <div className="mb-2 ">
                <small class="text-muted">
                  Order Time:{" "}
                  {moment(item.createdAt).format("h:mm a, DD-MM-YYYY")}
                </small>
              </div>
              <div className="d-flex justify-content-between">
                <div>
                  <div>
                    <small class="text-muted">{item.deliveryLocation}</small>
                  </div>
                  <div>
                    <small class="text-muted">{item.requestDate}</small>
                  </div>
                </div>
                <div>
                  <div>
                    <small class="text-muted">
                      Quantity {item.quantity} KG
                    </small>
                  </div>
                  <div>
                    <h6 class="fw-bold primary-color">
                      RM {item.sellingPrice}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  };
  return (
    <div>
      <div id="scrollableDiv" className="py-4">
        {renderNotifications()}
        <div className="mt-4 d-flex justify-content-center">
          <Pagination
            count={Math.ceil(props.totalCount / 10)}
            page={props.variables.page}
            onChange={(e, page) => {
              props.handlePageChange(page);
            }}
            variant="outlined"
            shape="rounded"
          />
        </div>
      </div>
    </div>
  );
}

export default ProductsMobile;
