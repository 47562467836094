import React from "react";
import * as AuthActions from "auth/store/actions";
import { useDispatch, useSelector } from "react-redux";
import logoLight from "assets/logo-light.svg";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

function Index() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutUser = (e) => {
    dispatch(AuthActions.logoutUser());
  };
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });

  if (Auth === false) {
    return null;
  }
  return (
    <div
      className="primary-background py-3 px-2  py-sm-3 px-sm-4 text-white border-dark "
      style={{ maxHeight: "120px" }}
    >
      <div className="d-flex justify-content-between">
        <div>
          <Link to="/">
            <img alt="Logo" src={logoLight} style={{ maxWidth: "68px" }}></img>
          </Link>
        </div>
        <div>
          <div onClick={handleClick} className="d-flex">
            <Avatar
              style={{
                backgroundColor: "white",
                color: "black",
                width: 38,
                height: 38,
              }}
            >
              {Auth?.name[0].toUpperCase()}
            </Avatar>
            <span className="ms-2 ms-sm-3 me-1 me-sm-3 align-self-center">
              {Auth?.name}
            </span>
            <ArrowDropDownIcon className=" me-2 me-sm-3 align-self-center" />
          </div>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={logoutUser}
              className="d-block d-flex p-2 bg-white"
            >
              <Logout className="me-2" /> Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default Index;
