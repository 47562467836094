import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import Select from "react-select";
import { reactSelectStyles } from "components/react-select/reactSelectStyles.js";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import ErrorMessageComponents from "components/ErrorHandlers/ErrorMessageComponents";
import CircularProgressComponent from "components/ErrorHandlers/CircularProgressComponent";
import History from "@history";
import moment from "moment";
import momentt from "moment-timezone";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Index(props) {
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  let orderId = props.match.params.id;
  const useStyles = makeStyles({
    dialogPaper: {
      position: "absolute",
      left: "0%",
      width: "100%",
      ["@media (max-width:525px)"]: {
        // eslint-disable-line no-useless-computed-key
        // bottom: "%",
        width: "100%",
      },
    },
    input: {
      borderColor: "#828282",
      borderRadius: "10px",
      backgroundColor: "#fff",
      padding: "2px 8px",
      paddingBottom: "24px",
    },
  });
  const classes = useStyles();
  const materialTheme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          display: "none",
          //   backgroundColor: lightBlue.A200,
          color: "red",
        },
      },
      MuiPickersCalendarHeader: {
        switchHeader: {
          // backgroundColor: lightBlue.A200,
          color: "black",
        },
      },
    },
  });
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  let dispatch = useDispatch();

  let [orderDialog, setOrderDialog] = useState(false);
  let [discardDialog, setDiscardDialog] = useState(false);
  let [isYearPickerOpen, setIsPickerOpen] = useState(false);
  let customerSearchTimerRef = useRef(null);
  let materialSearchTimerRef = useRef(null);

  const [customerSearchOpen, setCustomerSearchOpen] = useState(false);
  const [materialSearchOpen, setMaterialSearchOpen] = useState(false);

  let [customerOptions, setCustomerOptions] = useState([]);
  let [materialOptions, setMaterialOptions] = useState([]);

  let [formVal, setFormVal] = useState(null);
  let [defaultOrderVal, setDefaultOrderVal] = useState(null);
  let orderState = useSelector((state) => state.order);

  let userId = useSelector((state) => state?.auth?.user?.id);

  const [pageStatus, setPageStatus] = useState("Add");

  useEffect(() => {
    if (window.location.href.includes("editOrder")) {
      setPageStatus("Update");
    } else {
      setPageStatus("Add");
      dispatch(Actions.setMaterialCustomerNull());
    }
  }, []);
  useEffect(() => {
    console.log("Order Id --->", orderId);
    if (orderId) {
      dispatch(Actions.orderLoading(true));
      setTimeout(() => {
        dispatch(Actions.getOrder(orderId));
      }, 500);
    } else {
      setDefaultOrderVal(null);
    }
  }, [orderId]);

  useEffect(() => {
    let customers = [];
    let materials = [];
    if (orderState.customers) {
      customers = orderState.customers.map((customer) => {
        return { value: customer.id, label: customer.name };
      });
    }
    if (orderState.materials) {
      materials = orderState.materials.map((material) => {
        return { value: material.id, label: material.name };
      });
    }
    setCustomerOptions(customers);
    setMaterialOptions(materials);
  }, [orderState]);
  useEffect(() => {
    if (orderId && orderState.orderDetail) {
      // Formatting RequestDate as per datepicker requirement
      let orderDetail = orderState.orderDetail;
      let initDate = orderDetail?.requestDate;
      console.log("Init Date  !!! ", initDate);
      let dateSplitted = initDate.split("-");
      console.log("dateSplitted", dateSplitted);
      // console.log("Order Detail !!! ", orderDetail);
      let finalRequestDate = new Date(
        Number(dateSplitted[0]),
        Number(dateSplitted[1] - 1),
        Number(dateSplitted[2]),
        1
      );
      console.log("Final Request Date --->", finalRequestDate);
      let formCustomerVal = {
        label: orderDetail.customer.name,
        value: orderDetail.customer.id,
      };
      let formMaterialVal = {
        label: orderDetail.material.name,
        value: orderDetail.material.id,
      };
      setCustomerOptions([formCustomerVal]);
      setMaterialOptions([formMaterialVal]);

      setDefaultOrderVal({
        ...orderState.orderDetail,
        requestDate: finalRequestDate,
        customer: formCustomerVal,
        material: formMaterialVal,
      });

      reset({
        ...orderState.orderDetail,
        requestDate: finalRequestDate,
        customer: formCustomerVal,
        material: formMaterialVal,
      });
    }
  }, [orderState.orderDetail]);

  let fetchCustomers = (searchVal) => {
    if (searchVal) {
      // Search Debouncing to prevent immediate search
      if (customerSearchTimerRef.current) {
        clearTimeout(customerSearchTimerRef.current);
      }
      customerSearchTimerRef.current = setTimeout(() => {
        dispatch(Actions.orderStateLoadingCustomer(true));
        setTimeout(() => {
          dispatch(Actions.getOrderCustomers(searchVal, userId));
        }, 500);
      }, 500);
    }
  };
  let fetchMaterials = (searchVal) => {
    if (searchVal) {
      // Search Debouncing to prevent immediate search
      if (materialSearchTimerRef.current) {
        clearTimeout(materialSearchTimerRef.current);
      }
      materialSearchTimerRef.current = setTimeout(() => {
        dispatch(Actions.orderStateLoadingMaterial(true));
        setTimeout(() => {
          dispatch(Actions.getOrderMaterials(searchVal));
        }, 500);
      }, 500);
    }
  };

  const onSubmit = (data) => {
    let tz = moment().tz(momentt.tz.guess()).format("Z");
    console.log("Data ----->", data);
    let orderObj = {
      ...data,
      SalesPersonId: Auth.id,
      MaterialId: data.material.value,
      CustomerId: data.customer.value,
      status: data.status ? data.status : 0,
      date_order_place: data.date_order_place
        ? data.date_order_place
        : new Date(),
      tz: tz,
    };
    if (orderId) {
      delete orderObj.id;
      delete orderObj.createdAt;
      delete orderObj.updatedAt;
      delete orderObj.salesPerson;
    }
    if (!data.additionalInfo) {
      delete orderObj.additionalInfo;
    }
    delete orderObj.customer;
    delete orderObj.material;
    // console.log("Order obj final --->", orderObj);

    setOrderDialog(true);
    setFormVal(orderObj);
  };
  let sendOrder = () => {
    setOrderDialog(false);
    dispatch(Actions.orderStateLoading(true));
    if (orderId) {
      console.log("Calling update order form val final -->", formVal);
      dispatch(Actions.updateOrder(orderId, formVal));
    } else {
      console.log("Calling Add order form val final -->", formVal);
      dispatch(Actions.addOrder(formVal));
    }
  };

  if (orderState.Loading) {
    return <CircularProgressComponent />;
  }
  if (orderState.error) {
    return <ErrorMessageComponents message="Something went wrong." />;
  }
  console.log("defaultOrderVal --->", defaultOrderVal);

  var todayDate = new Date().toISOString().slice(0, 10);

  return (
    <div className="new_order_div_wrapper">
      <Dialog
        open={orderDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOrderDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description w-100"
        className="generalInfoDialog"
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <DialogContent className="">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="mb-2 text-dark fw-bold "
            style={{ fontSize: "20px" }}
          >
            Confirm
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="mb-4 text-dark"
          >
            Do you want to submit this order?
          </DialogContentText>
          <button
            onClick={() => {
              setOrderDialog(false);
            }}
            className="rounded-circle border-0 p-1 "
            style={{ position: "absolute", right: "5px", top: "5px" }}
          >
            <CloseIcon />
          </button>
          <button
            onClick={sendOrder}
            className="login-btn primary-background py-2 text-white btn w-100 d-block m-auto "
          >
            Send
          </button>
        </DialogContent>
      </Dialog>
      <Dialog
        open={discardDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOrderDialog(false);
        }}
        aria-describedby="alert-dialog-slide-description"
        className="generalInfoDialog"
        PaperProps={{ classes: { root: classes.dialogPaper } }}
      >
        <DialogContent className="">
          <DialogContentText
            id="alert-dialog-slide-description"
            className="mb-2 text-dark fw-bold "
            style={{ fontSize: "20px" }}
          >
            Confirm
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-slide-description"
            className="mb-4 text-dark"
          >
            Do you want to Discard this order?
          </DialogContentText>
          <button
            onClick={() => {
              setDiscardDialog(false);
            }}
            className="rounded-circle border-0 p-1 "
            style={{ position: "absolute", right: "5px", top: "5px" }}
          >
            <CloseIcon />
          </button>
          <button
            onClick={() => {
              History.goBack();
            }}
            className="login-btn primary-background py-2 text-white btn w-100 d-block m-auto "
          >
            OK
          </button>
        </DialogContent>
      </Dialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="new_order_div_body">
          <div className="pt-4 m-auto text-start order_form ">
            <h2 className="mb-4">{pageStatus} Order</h2>
            <div className="">
              <div className="mb-4">
                <label className="form_input_label mb-3" for="basic-url">
                  Customer Name
                </label>
                <Controller
                  name="customer"
                  control={control}
                  {...register("customer", {
                    required: "Customer Name is required",
                  })}
                  defaultValue={
                    defaultOrderVal ? defaultOrderVal.customer : null
                  }
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ width: "100%", padding: "0" }}
                      open={customerSearchOpen}
                      onOpen={() => {
                        setCustomerSearchOpen(true);
                      }}
                      onClose={() => {
                        setCustomerSearchOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option) => option.label}
                      options={customerOptions}
                      loading={orderState.orderStateLoadingCustomer}
                      hiddenLabel="true"
                      variant="outlined"
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          id="outlined-helperText"
                          label="Search Customer"
                          onChange={(e) => {
                            fetchCustomers(e.target.value);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            className: classes.input,
                            endAdornment: (
                              <React.Fragment>
                                {orderState.orderStateLoadingCustomer ? (
                                  <CircularProgress
                                    color="#111"
                                    size={20}
                                    style={{ paddingBottom: "4px !important" }}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />

                {errors.customer && (
                  <p className="formError">{errors.customer.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="form_input_label mb-3" for="basic-url">
                  Materials
                </label>
                <Controller
                  name="material"
                  control={control}
                  {...register("material", {
                    required: "Material is required",
                  })}
                  defaultValue={
                    defaultOrderVal ? defaultOrderVal.material : null
                  }
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      sx={{ width: "100%" }}
                      open={materialSearchOpen}
                      onOpen={() => {
                        setMaterialSearchOpen(true);
                      }}
                      onClose={() => {
                        setMaterialSearchOpen(false);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.label === value.label
                      }
                      getOptionLabel={(option) => option.label}
                      options={materialOptions}
                      loading={orderState.orderStateLoadingMaterial}
                      hiddenLabel="true"
                      variant="outlined"
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          id="outlined-helperText"
                          label="Search Material"
                          onChange={(e) => {
                            fetchMaterials(e.target.value);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            className: classes.input,
                            endAdornment: (
                              <React.Fragment>
                                {orderState.orderStateLoadingMaterial ? (
                                  <CircularProgress color="#111" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
                {errors.material && (
                  <p className="formError">{errors.material.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="form_input_label mb-2" for="basic-url">
                  Quantity
                </label>
                <div className="d-flex">
                  <Controller
                    name="quantity"
                    control={control}
                    {...register("quantity", {
                      required: "Quantity is required",
                    })}
                    defaultValue={defaultOrderVal?.quantity}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="number"
                        min="0"
                        step="0.001"
                        value={value}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            onChange(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          onChange(Number(e.target.value).toFixed(3));
                        }}
                        placeholder="0.000"
                        className="form-control w-100 form_input_border"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        style={{ textAlign: "right" }}
                      />
                    )}
                  />

                  <div className=" rounded ms-3  text-center">
                    <h5 className="p-2 mb-0">KG</h5>
                  </div>
                </div>
                {errors.quantity && (
                  <p className="formError">{errors.quantity.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="form_input_label mb-2" for="basic-url">
                  Selling Price
                </label>
                <div className="d-flex">
                  <Controller
                    name="sellingPrice"
                    control={control}
                    {...register("sellingPrice", {
                      required: "Selling price is required",
                    })}
                    defaultValue={defaultOrderVal?.sellingPrice}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="number"
                        min="0"
                        step="0.01"
                        value={value}
                        onChange={(e) => {
                          if (e.target.value >= 0) {
                            onChange(e.target.value);
                          }
                        }}
                        onBlur={(e) => {
                          onChange(Number(e.target.value).toFixed(2));
                        }}
                        placeholder="0.00"
                        className="form-control w-100 form_input_border"
                        id="basic-url"
                        aria-describedby="basic-addon3"
                        style={{ textAlign: "right" }}
                      />
                    )}
                  />
                  <div className=" rounded ms-3  text-center">
                    <h5 className="p-2 mb-0">RM</h5>
                  </div>
                </div>
                {errors.sellingPrice && (
                  <p className="formError">{errors.sellingPrice.message}</p>
                )}
              </div>
              <div className="mb-4">
                <label for="basic-url" className="form_input_label mb-2">
                  Delivery Location
                </label>
                <div class="input-group ">
                  <input
                    type="text"
                    className="form-control w-100 form_input_border"
                    id="basic-url"
                    defaultValue={defaultOrderVal?.deliveryLocation}
                    aria-describedby="basic-addon3"
                    {...register("deliveryLocation", {
                      required: "Location is required",
                    })}
                  />
                  {errors.deliveryLocation && (
                    <p className="formError">
                      {errors.deliveryLocation.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-4">
                <label className="form_input_label mb-2 " for="basic-url">
                  Request Date
                </label>
                <div className="input-group ">
                  <Controller
                    name="requestDate"
                    control={control}
                    defaultValue={defaultOrderVal?.requestDate}
                    {...register("requestDate", {
                      required: "Date is Required.",
                    })}
                    render={({ field: { onChange, value } }) => (
                      <ThemeProvider theme={materialTheme}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            onClick={() => setIsPickerOpen(true)}
                            open={isYearPickerOpen}
                            onClose={() => setIsPickerOpen(false)}
                            variant="inline"
                            minDate={todayDate}
                            onChange={(e) => {
                              onChange(e);
                              setIsPickerOpen(false);
                            }}
                            value={value ? value : null}
                            format="dd-MMMM-yyyy"
                            className="date_picker"
                            placeholder="Select Request Date"
                            InputProps={{
                              disableUnderline: true,
                              readOnly: true,
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    )}
                  />
                  {errors.requestDate && (
                    <p className="formError">{errors.requestDate.message}</p>
                  )}
                </div>
              </div>
              <div className="mb-6">
                <label for="basic-url" className="form_input_label mb-2">
                  Additional Information
                </label>
                <div class="input-group ">
                  <textarea
                    {...register("additionalInfo", {})}
                    className="form-control form_input_border"
                    id="additionalInfo"
                    style={{ resize: "none", height: "150px" }}
                    defaultValue={defaultOrderVal?.additionalInfo}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-top  ">
          <div className="py-3 text-start  new_order_div_body d-flex justify-content-end">
            <div
              onClick={() => {
                setDiscardDialog(true);
              }}
              className="btn py-2 px-4  me-4 border"
            >
              Discard
            </div>
            <button
              className="primary-background py-2 px-4 text-white btn "
              type="submit"
            >
              {pageStatus}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Index;
