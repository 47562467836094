import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import LoginTopLeftBlob from "assets/loginTopLeftBlob.png";
import LoginBtmRightBlof from "assets/loginBtmRightBlob.png";
import Logo from "assets/logo.png";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@material-ui/core/styles";
import * as AuthAction from "auth/store/actions";
import History from "@history";
import "./login.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Index() {
  const useStyles = makeStyles({
    dialogPaper: {
      position: "absolute",
      left: "0%",
      width: "100%",
      ["@media (max-width:525px)"]: {
        // eslint-disable-line no-useless-computed-key
        // bottom: "%",
        width: "100%",
      },
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const loading = useSelector(({ auth }) => auth.login.loading);
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    if (Auth) {
      History.push({
        pathname: "/",
      });
    }
  }, [Auth]);

  const onSubmitLogin = (e) => {
    e.preventDefault();
    let data = {
      username: e.target.username.value,
      password: e.target.password.value,
    };
    dispatch(AuthAction.loginLoading(true));
    dispatch(AuthAction.loginAdmin(data));
  };

  return (
    <div>
      {loading && <LinearProgress />}
      <div className="login-wrapper p-4">
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          className="generalInfoDialog"
          PaperProps={{ classes: { root: classes.dialogPaper } }}
        >
          <DialogTitle className="fw-bold">{"Forgot Password?"}</DialogTitle>
          <DialogContent className="">
            <DialogContentText
              id="alert-dialog-slide-description"
              className="mb-4 text-dark"
            >
              To reset your password please contact your admin
            </DialogContentText>
            <button
              onClick={handleClose}
              className="rounded-circle border-0 p-1 "
              style={{ position: "absolute", right: "5px", top: "5px" }}
            >
              <CloseIcon />
            </button>
            <button
              onClick={handleClose}
              className="login-btn primary-background py-2 text-white btn w-75 d-block m-auto "
            >
              OK
            </button>
          </DialogContent>
        </Dialog>
        <img
          alt="background img left"
          className="loginTopLeftBlob"
          src={LoginTopLeftBlob}
        ></img>
        <img
          alt="background img right"
          className="loginBtmRightBlob"
          src={LoginBtmRightBlof}
        ></img>
        <form onSubmit={(e) => onSubmitLogin(e)}>
          <div className="login-div  text-start   m-auto">
            <div className="mb-4 text-center">
              <img alt="Logo" className="login-logoImg" src={Logo}></img>
            </div>
            <div className="login-div-body">
              <div className="mb-5">
                <label for="basic-url" className="form-label form_input_label">
                  Email
                </label>
                <div class="input-group ">
                  <input
                    type="email"
                    name="username"
                    id="user-mail"
                    class="form-control form_input_border "
                    aria-describedby="basic-addon3"
                    required
                  />
                </div>
              </div>
              <div className="mb-5">
                <label for="basic-url" className="form-label form_input_label">
                  Password
                </label>
                <div class="input-group ">
                  <input
                    type="Password"
                    name="password"
                    class="form-control form_input_border"
                    aria-describedby="basic-addon3"
                    minlength="6"
                    required
                  />
                </div>
              </div>
              <div className="mb-7">
                <span
                  onClick={handleOpen}
                  className="primary-color fw-bold"
                  style={{ cursor: "pointer" }}
                >
                  Forgot Password?
                </span>
              </div>
              <div className="text-center w-100 ">
                {/* <Link to="/home"> */}
                <button
                  type="submit"
                  className="login-btn primary-background py-2 text-white btn w-75 d-block m-auto"
                >
                  Login
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Index;
