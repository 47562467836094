import React, { useEffect, useState } from "react";
import * as Actions from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import "./index.css";
import ProductsDesktop from "./productsDesktop.js";
import ProductsMobile from "./productsMobile.js";
import history from "@history";

import { useMediaQuery } from "react-responsive";

function Index(props) {
  let dispatch = useDispatch();
  const Auth = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  let querystr = qs.parse(props.location.search);
  let defaultVariables = {
    limit: 10,
    offset: 0,
    page: 1,
  };
  if (Object.keys(querystr).length === 0) {
    querystr = {
      ...defaultVariables,
    };
  }
  let [variables, setVariables] = useState({
    ...querystr,
    page: querystr.page ? JSON.parse(querystr.page) : 1,
  });

  let [orderData, setOrderData] = useState([]);
  let orderState = useSelector((state) => state.order);

  useEffect(() => {
    const q_str = qs.stringify(variables);
    history.push({ search: q_str });
    console.log("Get Variables --->", variables);
    if (Auth) {
      dispatch(Actions.orderLoading(true));
      setTimeout(() => {
        dispatch(Actions.getOrders({ ...variables }));
      }, 500);
    }
  }, [dispatch, variables]);

  useEffect(() => {
    if (orderState.orders) {
      console.log("Customer state --->", orderState.orders);
      setOrderData(orderState.orders);
    }
  }, [orderState.orders]);
  const handlePageChange = (pg) => {
    pg = pg - 1;
    let offsetChange = variables.limit * pg;
    setVariables({ ...variables, offset: offsetChange, page: pg + 1 });
  };
  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 525 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 525 });
    return isMobile ? children : null;
  };
  const [searchValue, setSearchValue] = useState(
    (variables && variables["any"]) || ""
  );
  let submitFunc = (e) => {
    e.preventDefault();
    if (searchValue) {
      let searchObj = {};
      searchObj["any"] = searchValue;
      setVariables({
        ...searchObj,
        ...defaultVariables,
      });
    }
  };

  return (
    <div className="order_home_wrapper">
      <div id="main-Body " className="pt-6 order_div_body">
        <div className=" d-flex justify-content-between mb-4">
          <h2>Orders</h2>
          <button
            className="primary-background py-2 px-4 text-white btn"
            onClick={() => {
              history.push("/newOrder");
            }}
          >
            Create Order
          </button>
        </div>
        <form id="searchForm" onSubmit={(e) => submitFunc(e)}>
          <div className="d-flex flex-row ">
            <div className="home-search-input align-self-center">
              <input
                type="text"
                id="basic-url"
                aria-describedby="basic-addon3"
                placeholder="Search"
                className="p-2  w-100 form_input_border"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    submitFunc(e);
                  }
                }}
              />
            </div>
            <div>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("searchForm").reset();
                  if (searchValue || variables["any"]) {
                    setVariables(defaultVariables);
                  }
                  setSearchValue("");
                }}
                className=" ms-3 py-2 px-2 form_input_border bg-white "
                style={{ height: "39px" }}
              >
                Clear
              </button>
            </div>
          </div>
        </form>
        <div>
          <Desktop>
            <ProductsDesktop
              items={orderData}
              totalCount={orderState.count}
              handlePageChange={handlePageChange}
              variables={variables}
              orderState={orderState}
              loading={orderState.Loading}
            />
          </Desktop>

          <Mobile>
            <ProductsMobile
              items={orderData}
              variables={variables}
              setVariables={setVariables}
              handlePageChange={handlePageChange}
              totalCount={orderState.count}
              orderState={orderState}
              loading={orderState.Loading}
            />
          </Mobile>
        </div>
      </div>
    </div>
  );
}

export default Index;
