import * as Actions from "../actions";

import moment from "moment";

const initialState = {
  Loading: false,
  orderStateLoading: false,
  orderStateLoadingMaterial: false,
  orderStateLoadingCustomer: false,
  orders: null,
  orderDetail: null,
  materials: null,
  customers: null,
  count: 0,
  error: null,
};

const orderReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ORDERS: {
      console.log("Get order reducer");
      return {
        ...state,
        orders: action.payload.rows,
        count: action.payload.count,
        Loading: false,
        error: null,
      };
    }
    case Actions.GET_ORDER: {
      return {
        ...state,
        orderDetail: action.payload,
        Loading: false,
        orderStateLoading: false,
        error: null,
      };
    }
    case Actions.GET_ORDER_MATERIALS: {
      return {
        ...state,
        orderStateLoading: false,
        orderStateLoadingMaterial: false,
        materials: action.payload.rows,
      };
    }
    case Actions.GET_ORDER_CUSTOMERS: {
      return {
        ...state,
        orderStateLoading: false,
        orderStateLoadingCustomer: false,
        customers: action.payload.rows,
      };
    }
    case Actions.GET_ORDERS_ERROR: {
      return {
        ...state,
        Loading: false,
        orderStateLoading: false,
        error: action.payload,
      };
    }
    case Actions.GET_ORDERS_MATERIALS_ERROR: {
      return {
        ...state,
        Loading: false,
        orderStateLoadingCustomer: false,
        orderStateLoadingMaterial: false,
      };
    }
    case Actions.ORDER_MATERIAL_CUSTOMER_NULL: {
      return {
        ...state,
        materials: null,
        customers: null,
      };
    }
    case Actions.NEW_ORDER_ERROR: {
      return {
        ...state,
        Loading: false,
        error: true,
      };
    }
    case Actions.ORDERS_LOADING: {
      return {
        ...state,
        Loading: action.payload,
      };
    }
    case Actions.ORDERS_STATE_LOADING: {
      return {
        ...state,
        orderStateLoading: action.payload,
      };
    }
    case Actions.ORDERS_STATE_LOADING_CUSTOMER: {
      return {
        ...state,
        orderStateLoadingCustomer: action.payload,
      };
    }
    case Actions.ORDERS_STATE_LOADING_MATERIAL: {
      return {
        ...state,
        orderStateLoadingMaterial: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default orderReducer;
