import History from "@history";
import React from "react";
import { Button } from "reactstrap";

export default function ErrorMessageComponents(props) {
  return (
    <div
      className="page-loader d-flex flex-column justify-content-center align-items-center"
      style={{ minHeight: "400px" }}
    >
      <h2 className="font-weight-bold">Error! {props.message}</h2>
      <Button
        className="primary-background py-2 px-4 text-white btn mt-3"
        onClick={(e) => History.goBack()}
      >
        Back
      </Button>
    </div>
  );
}
