import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  render() {
    return (
      <div className="error-wrapper " key="1">
        <div className="session-inner-wrapper">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-9 mx-auto">
              <div className="error-body text-center">
                <h2 className="bold mb-4">404</h2>
                <h2 className="error-msg mb-30">Sorry, page not found</h2>
                <h4 className="mb-4">
                  {" "}
                  Looks like you have navigated too far.
                </h4>
                <Button
                  component={Link}
                  to="/"
                  variant="contained"
                  className="btn-light btn-lg"
                >
                  Go To Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
