import Home from "routes/home";
import Order from "routes/order";

export default [
  {
    path: "/",
    component: Home,
  },
  {
    path: "newOrder",
    component: Order,
  },
  {
    path: "editOrder/:id",
    component: Order,
  },
];
