import Domain from "lib/config";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import History from "@history";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_MATERIALS = "GET_ORDER_MATERIALS";
export const GET_ORDER_CUSTOMERS = "GET_ORDER_CUSTOMERS";
export const ORDER_MATERIAL_CUSTOMER_NULL = "ORDER_MATERIAL_CUSTOMER_NULL";
export const GET_ORDERS_MATERIALS_ERROR = "GET_ORDERS_MATERIALS_ERROR";
export const NEW_ORDER_ERROR = "NEW_ORDER_ERROR";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";
export const ORDERS_LOADING = "ORDERS_LOADING";
export const ORDERS_STATE_LOADING = "ORDERS_STATE_LOADING";
export const ORDERS_STATE_LOADING_CUSTOMER = "ORDERS_STATE_LOADING_CUSTOMER";
export const ORDERS_STATE_LOADING_MATERIAL = "ORDERS_STATE_LOADING_MATERIAL";

export function getOrders(variables) {
  delete variables.page;
  const request = axios.get(`${Domain}/api/salesPerson/all/orders`, {
    params: variables,
  });

  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_ORDERS,
          payload: response.data.data,
          count: response.data.count,
        });
      })
      .catch((error) => {
        error.response && error.response.data.msg
          ? NotificationManager.error(error.response.data.msg)
          : NotificationManager.error("Error! Cannot fetch orders");
        return dispatch({
          type: GET_ORDERS_ERROR,
        });
      });
}
export function getOrder(id) {
  const request = axios.get(`${Domain}/api/order/${id}`, {
    params: { id },
  });
  return (dispatch) =>
    request
      .then((response) => {
        console.log("Response --->", response);
        return dispatch({
          type: GET_ORDER,
          payload: response.data.data,
          count: response.data.count,
        });
      })
      .catch((error) => {
        console.log("Error get order --->", error.response);
        error.response && error.response.data.msg
          ? NotificationManager.error(error.response.data.msg)
          : NotificationManager.error("Error! Cannot fetch order detail");
        return dispatch({
          type: GET_ORDERS_ERROR,
          error: "Cannot Get Order",
        });
      });
}
export function addOrder(data) {
  const request = axios.post(`${Domain}/api/order`, data);
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(orderStateLoading(false));
        History.push("/");
        NotificationManager.success("Order Added Successfully");
        return dispatch({
          type: "ADD_ORDER_SUCCESS",
        });
      })
      .catch((error) => {
        dispatch(orderStateLoading(false));
        console.log("Err --->", error);
        console.log("Error --->", error.response);
        NotificationManager.error(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Cannot Add Order"
        );
        return dispatch({
          type: GET_ORDERS_ERROR,
          error: "Cannot Add Order",
        });
      });
}
export function updateOrder(id, data) {
  console.log("Data --->", data);
  const request = axios.put(
    `${Domain}/api/salesPerson/updateOrder/${id}`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(orderStateLoading(false));
        History.push("/");
        NotificationManager.success("Order Updated Successfully");
        return dispatch({
          type: "UPDATE_ORDER_SUCCESS",
        });
      })
      .catch((error) => {
        console.log("error --->", error.response);
        dispatch(orderStateLoading(false));
        NotificationManager.error(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "Cannot Update Order"
        );
        return dispatch({
          type: GET_ORDERS_ERROR,
          error: "Cannot Update Order",
        });
      });
}
export function getOrderMaterials(searchVal) {
  let variables = null;
  if (searchVal) {
    variables = {
      name: searchVal,
    };
  }
  const request = axios.get(`${Domain}/api/materials`, {
    params: variables,
  });
  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_ORDER_MATERIALS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log("mareials Error --->", error.response);
        return dispatch({
          type: NEW_ORDER_ERROR,
        });
      });
}
export function getOrderCustomers(searchVal, userId) {
  console.log(userId);
  let variables = null;
  if (searchVal) {
    variables = {
      name: searchVal,
      SalesPersonId: userId,
    };
  }
  const request = axios.get(`${Domain}/api/customer`, {
    params: variables,
  });

  return (dispatch) =>
    request
      .then((response) => {
        return dispatch({
          type: GET_ORDER_CUSTOMERS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log("customers Error --->", error.response);
        return dispatch({
          type: GET_ORDERS_MATERIALS_ERROR,
        });
      });
}
export function orderStateLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ORDERS_STATE_LOADING,
      payload: val,
    });
  };
}
export function orderStateLoadingCustomer(val) {
  return (dispatch) => {
    return dispatch({
      type: ORDERS_STATE_LOADING_CUSTOMER,
      payload: val,
    });
  };
}
export function orderStateLoadingMaterial(val) {
  return (dispatch) => {
    return dispatch({
      type: ORDERS_STATE_LOADING_MATERIAL,
      payload: val,
    });
  };
}
export function orderLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ORDERS_LOADING,
      payload: val,
    });
  };
}
export function setMaterialCustomerNull() {
  return (dispatch) => {
    return dispatch({
      type: ORDER_MATERIAL_CUSTOMER_NULL,
    });
  };
}
