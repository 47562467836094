import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { NotificationContainer } from "react-notifications";
import { Route, Switch } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import PageNotFound from "pages/404";
import History from "@history";
import LoginPage from "routes/login";
import MainNavBar from "components/mainNavbar/index";
import routerService from "services/routeServices";
import "./App.css";

function App(props) {
  const authCheck = useSelector(({ auth }) => {
    return auth.login.success ? auth.user : false;
  });
  useEffect(() => {
    if (authCheck === false) {
      History.push({
        pathname: "/login",
      });
    }
  }, [authCheck]);

  const getUrl = (pathname) => {
    return pathname === "/login" ? true : false;
  };
  const { location } = props;

  return (
    <div className="App">
      <NotificationContainer />
      <Switch>
        {getUrl(location.pathname) ? (
          <Route
            exact
            path="/login"
            render={(props) => <LoginPage {...props} />}
          />
        ) : (
          <>
            <MainNavBar />
            <Switch>
              {routerService &&
                routerService.map((route, key) => (
                  <Route
                    key={key}
                    path={`/${route.path}`}
                    component={route.component}
                  />
                ))}

              <Route component={PageNotFound} />
            </Switch>
          </>
        )}
      </Switch>
    </div>
  );
}

export default App;
